@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

:root {
  --primary-color: #1d3557;
  --secondary-color: whitesmoke;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}

/* helps in in-details page */
h6 {
  font-size: 1rem;
  font-weight: 700;
}

pre,
code {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 0.7rem;
  border-radius: 4px;
  font-family: 'Fira Code', monospace;
}

.navbar-container {
  background-color: var(--primary-color);
  padding: 0.4rem 0;
}

.container {
  height: 100%;
  padding: 1rem 0;
}
.parent-container {
  display: flex;
  justify-content: space-between;
}
.filter-container {
  display: flex;
  gap: '16px';
}
.input-box {
  width: 20rem;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prioity-input {
  width: 48px !important;
  height: 32px !important;
  border-radius: 0px !important;
}
.justify-content-space-between {
  justify-content: space-around;
}

.shadow {
  box-shadow: inset 0px 25px 100px -16px rgba(0, 0, 0, 0.1);
}

.card {
  width: 100%;
  padding: 1rem;
  box-shadow: inset 0px 25px 100px -16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 1rem 0;
  text-align: center;
}

.section {
  padding-top: 2rem;
}

.img-container {
  width: 95%;
  padding: 0.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  transition: all 0.2s;
  height: 95%;
}

.img-container img {
  width: 100%;
  height: 100%;
  transition: all 0.2s;
}

.img-container img:hover {
  transform: scale(1.01);
}

.ant-table table {
  font-size: 1rem;
}
.ant-input-number-input {
  text-align: center !important;
  padding: 0 !important;
}
.rightbtn.ant-btn {
  background-color: #7389f2 !important;
  color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 32px;
}

.leftbtn.ant-btn {
  background-color: #7389f2 !important;
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 32px;
}

.rightbtn.ant-btn:hover,
.leftbtn.ant-btn:hover {
  background-color: #7389f2 !important;
  color: white;
}

.rightbtn.ant-btn:disabled,
.leftbtn.ant-btn:disabled {
  background-color: #7389f2 !important;
  color: white;
}

.rightbtn.ant-btn:disabled:hover,
.leftbtn.ant-btn:disabled:hover {
  background-color: #7389f2 !important;
  color: white;
  opacity: 1;
}

.rightbtn.ant-btn:focus,
.leftbtn.ant-btn:focus {
  background-color: #7389f2 !important;
  color: white;
  outline: none;
}
.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-content img {
  width: 120px;
}

.navbar-content p {
  color: var(--secondary-color);
  padding-top: 0.7rem;
}

.navbar-userdetails {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.m-tb {
  margin: 1rem 0;
}

.home-section-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.search-bar {
  width: 40%;
}

.filter-status {
  width: 15%;
  margin: 0 1rem;
}

.home-section-div {
  width: 70%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.date-range-search {
  margin: 1rem 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 90vw;
}

.google_login_btn {
  display: flex;
  justify-content: center;
  width: 100vw;
  margin: 1rem;
}

.copy-btn {
  position: absolute !important;
  top: 10;
  right: 10px;
  color: var(--secondary-color);
  font-size: 1.2rem;
  cursor: pointer;
}

.txt-aln-ctr {
  text-align: center;
}

/*width*/
.half-width {
  width: 50%;
}
.priority-popover-container {
  display: flex;
  height: 15px;
  gap: 5px;
}
.priority-popover-title {
  display: flex;
  justify-content: right;
  align-items: center;
}
.slider-container {
  width: 150px;
}

.card-row-container {
  border-radius: 0.375rem;
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
}

.card-info-container {
  padding: 0.5rem 0.875rem 0.5rem 0.875rem;
  display: flex;
  flex-direction: row;
}

.file-icon {
  color: #ffb11f !important;
  font-size: 2rem;
  padding: 0.125rem;
}

.file-detail-container {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  padding-top: 0.2rem;
}

.file-upper-detail {
  color: #212121;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1rem;
}

.file-lower-detail {
  color: #808080;
  font-size: 0.7rem;
  font-weight: 500;
}

.close-circle-outlined-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 0.875rem;
  color: red;
}

.upload-button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.7rem;
}

.ant-slider {
  margin: 5px !important;
  padding: 0;
}
.priority-filter-selected {
  color: '#000000';
  opacity: 1;
}
.priority-filter-unselected {
  opacity: 0.5;
  border: 1px solid #9e9e9e !important;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row-center {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.icon-style {
  color: #1990ff;
}
.ml {
  margin-left: 5px;
}
@media only screen and (max-width: 600px) {
  .img-container {
    width: 100%;
  }

  .home-section-div {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .date-range-search {
    margin: 0;
  }

  .search-bar {
    width: 100%;
  }

  .filter-status {
    width: 100%;
    padding: 0;
    margin: 1rem 0;
  }
}
